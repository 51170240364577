/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "gatsby-syntax-highlights-dracula"
import "./src/css/index.css"
//require("prismjs/themes/prism-solarizeddark.css")
